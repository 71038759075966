<template>
    <table-container>
        <data-iterator ref="iterator" :api="api" :filters.sync="filters">
            <template v-slot:actions>
                <slot name="actions"></slot>
            </template>

            <template slot-scope="{ items }">
                <d-table
                    :items="items"
                    :headers="headers"
                    :filters.sync="filters"
                    row-select
                    row-action
                    @onRowSelected="onRowSelected"
                >
                    <template v-slot:[`item.token`]="{ item }">
                        <div class="word-break">
                            {{ item.token }}
                        </div>
                    </template>

                    <!-- row actions -->
                    <template v-slot:rowActions="{ item }">
                        <edit-button :to="getEditLink(item)"></edit-button>
                        <delete-action
                            :entity-name="item.name"
                            :entity-id="item.id"
                            :api="api"
                            @deleted="onEntityDeleted"
                            @failed="onEntityDeleteFailed"
                        ></delete-action>
                        <edit-button
                            :href="`${baseURL}/public/custom-export?token=${item.token}`"
                            target="_blank"
                            icon="get_app"
                            tooltip="Download"
                        ></edit-button>
                    </template>
                </d-table>
            </template>

            <!-- batch actions -->
            <template v-slot:batchActions>
                <delete-batch-action
                    :selected-ids="selectedIds"
                    :api="api"
                    @finished="onBatchDeleteFinished"
                    @finishedWithErrors="onBatchDeleteFinishedWithErrors"
                    @canceled="onBatchDeleteCanceled"
                ></delete-batch-action>
            </template>
        </data-iterator>
    </table-container>
</template>

<script>
import routeType from '@/router/routeType'
import RestApiType from '@/api/RestApiType'
import DataIteratorMixin from '@/components/mixins/DataIteratorMixin'
import DataTableMixin from '@/components/mixins/DataTableMixin'
import Headers from '@/pages/customExport/mixins/Headers'
import Config from '@/config.loader'

export default {
    mixins: [DataIteratorMixin, DataTableMixin, Headers],

    data() {
        return {
            api: RestApiType.CUSTOM_EXPORT,
            baseURL: Config.getConfigValue('VUE_APP_ADMIN_API_BASE'),
        }
    },
    computed: {
        tableName() {
            return this.$t('labels.custom_export')
        },
    },

    methods: {
        getEditLink(item) {
            return {
                name: routeType.CUSTOM_EXPORT_EDIT,
                params: { id: item.id },
            }
        },
    },
}
</script>
